import { useEffect, useState } from "react"

import Product from './Product.jsx';

import { ShopProducts } from "../../constants/AccountsInfo.js";

import { showElementById } from "../../util/hideShowElementById.js";

function ProductSection()
{
    const [ products, setProducts ] = useState(ShopProducts)

    useEffect(() => 
    {
        if (window.innerWidth > 1025)
            showElementById('pn-nav-bar');
        showElementById('n-b-top');
        // getProducts().then(retrievedProducts =>
        // {
        //     if (retrievedProducts) {
        //         retrievedProducts.forEach(product => {
        //             const productNameId = product.productNameId;
        //             ShopProducts.get(productNameId).quantity = product.quantity
        //         })
        //     }
        //     setProducts(ShopProducts)
        // })
        setProducts(ShopProducts)
    }, [products])

    return (
        <div id='store'>
            {/* <div className='store-top-msg'>
                <p className='store-top-msg-p'><b>What is an alt?</b> Alt = Alternative Account</p>
                <p className='store-top-msg-p'>Each account was Created by Ourselves and checked to 100% work!</p>
                <p className='store-top-msg-p'>The account credentials can be changed and each account comes with a warranty. The account is completely your own.</p>
            </div> */}
            <h2 id="mc-fa" className="section-header">Minecraft Best Sellers</h2>
            <div className='store-grid-container'>
                {/* <Link className="stats-finder-item" to={`/statsfinder`}>
                    <div className='stats-finder-header'>
                        <div className="stats-finder-title">Account Finder</div>
                    </div>
                    <div className="stats-img-holder">
                        <img src={Magnify} alt=""/>
                    </div>
                    <div className='stats-finder-info'>
                        <div className="stats-mini-details">
                            <h3 className="stats-finder-desc-header">What is this?</h3>
                            <div className="stats-finder-description">
                                Search for accounts matching your specifications. Hypixel stats, capes and more filters coming soon!
                            </div>
                            <button className="stats-finder-search">Search</button>
                        </div>
                    </div>
                </Link> */}
                <Product
                    product={products.get('fresh-fa')}/>
                <Product
                    product={products.get('banned-fa')}/>
                <Product
                    product={products.get('unbanned-fa')}/>
                <Product
                    product={products.get('xbox-gamepass-accounts')}/>
            </div>
            <h2 id="hypixel-fas" className="section-header hypixel-alts">Hypixel Full Access</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('mvp+-fa')}/>
                <Product
                    product={products.get('mvp+-fa-100')}/>
                <Product
                    product={products.get('mvp+-fa-250')}/>
                <Product
                    product={products.get('lvl-mvp-fa')}/>
            </div>
            <div className='store-grid-container'>
                <Product
                    product={products.get('level-100-fa')}/>
                <Product
                    product={products.get('level-fa')}/>
                <Product
                    product={products.get('lvl-vip-fa')}/>
                <Product
                    product={products.get('lvl-vip+-fa')}/>
            </div>
            <h2 id="hypixel-temp" className="section-header">Temporary Alts</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('xbox-gamepass-accounts')}/>
            </div>
            <h2 id="skyblock" className="section-header">Skyblock</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('skyblock-fa')}/>
                <Product
                    product={products.get('skyblock-50-fa')}/>
                <Product
                    product={products.get('skyblock-coins')}/>
            </div>
            <h2 id="capes" className="section-header">Capes</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('migrator-cape-fa')}/>
                <Product
                    product={products.get('vanilla-cape-fa')}/>
                <Product
                    product={products.get('cherry-blossom-fa')}/>
            </div>
            <h2 id="vpns" className="section-header">VPNs</h2>
            <div className='store-grid-container'>
                <Product
                    product={products.get('piavpn')}/>
            </div>
            {/* <h2 id="discord" className="section-header">Discord</h2> */}
            <div className='store-grid-container'>
                {/* <Product
                    product={products.get('nitro-three-month')}/>
                <Product
                    product={products.get('1month-boost')}/>
                <Product
                    product={products.get('3month-boost')}/> */}
            </div>
        </div>
    );
}

export default ProductSection;